import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { SideNavigation, IconHome, IconMap, IconCogwheel, Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/side-navigation/code",
  "title": "SideNavigation - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const IconCrossCircle = makeShortcode("IconCrossCircle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [active, setActive] = React.useState('/archive');
  const setActivePage = (e) => {
    e.preventDefault();
    setActive(e.target.getAttribute('href'));
  }
  return (<div
    style={{
      backgroundColor: '#f5f5f5',
      display: 'grid',
    }}
  >
    <div
      style={{
        height: '100%',
        maxWidth: '400px'
      }}
    >
      <SideNavigation
        defaultOpenMainLevels={[]}
        id="side-navigation"
        toggleButtonLabel="Navigate to page"
      >
        <SideNavigation.MainLevel
          id="#main-level-1"
          label="Events"
        >
          <SideNavigation.SubLevel
            href="/events"
            active={active === '/events'}
            id="sub-level-1"
            label="All events"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/archive"
            active={active === '/archive'}
            id="sub-level-2"
            label="Archive"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/new-event"
            active={active === '/new-event'}
            id="sub-level-3"
            label="Create a new event"
            onClick={setActivePage}
          />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel
          id="main-level-2"
          label="Events on map"
        >
          <SideNavigation.SubLevel
            href="/map"
            active={active === '/map'}
            id="sub-level-4"
            label="Map"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/edit-event-locations"
            active={active === '/edit-event-locations'}
            id="sub-level-5"
            label="Edit event locations"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/new-location"
            active={active === '/new-location'}
            id="sub-level-6"
            label="Create a new location"
            onClick={setActivePage}
          />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel
          href="/settings"
          active={active === '/settings'}
          id="main-level-3"
          label="Settings"
          onClick={setActivePage}
        />
        <SideNavigation.MainLevel
          external
          href="https://tapahtumat.hel.fi/"
          openInNewTab
          openInNewTabAriaLabel="Opens in a new tab."
          openInExternalDomainAriaLabel="Opens a different website."
          id="main-level-4"
          label="Tapahtumat.hel.fi"
          openInExternalDomainAriaLabel="Opens a different website"
          withDivider
        />
      </SideNavigation>
    </div>
  </div>)
}}
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "with-icons",
      "style": {
        "position": "relative"
      }
    }}>{`With icons`}<a parentName="h4" {...{
        "href": "#with-icons",
        "aria-label": "with icons permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() =>  {
  const [active, setActive] = React.useState('/archive');
  const setActivePage = (e) => {
    e.preventDefault();
    setActive(e.target.getAttribute('href'));
  }
  return (<div
    style={{
      backgroundColor: '#f5f5f5',
      display: 'grid',
    }}
  >
    <div
      style={{
        height: '100%',
        maxWidth: '400px'
      }}
    >
      <SideNavigation
        defaultOpenMainLevels={[]}
        id="side-navigation-icons"
        toggleButtonLabel="Navigate to page"
      >
        <SideNavigation.MainLevel
          icon={<IconHome aria-hidden />}
          id="main-level-icons-1"
          label="Events"
        >
          <SideNavigation.SubLevel
            href="/events"
            id="sub-level-icons-1"
            active={active === '/all-events'}
            label="All events"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/archive"
            id="sub-level-icons-2"
            active={active === '/archive'}
            label="Archive"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/new-event"
            id="sub-level-icons-3"
            active={active === '/new-event'}
            label="Create a new event"
            onClick={setActivePage}
          />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel
          icon={<IconMap aria-hidden />}
          id="main-level-icons-2"
          label="Events on map"
        >
          <SideNavigation.SubLevel
            href="/map"
            id="sub-level-icons-4"
            active={active === '/map'}
            label="Map"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/edit-event-locations"
            id="sub-level-icons-5"
            active={active === '/edit-event-locations'}
            label="Edit event locations"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/new-location"
            id="sub-level-6"
            active={active === '/new-location'}
            label="Create a new location"
            onClick={setActivePage}
          />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel
          href="/settings"
          icon={<IconCogwheel aria-hidden />}
          id="main-level-icons-3"
          active={active === '/settings'}
          label="Settings"
          onClick={setActivePage}
        />
        <SideNavigation.MainLevel
          external
          href="https://tapahtumat.hel.fi/"
          openInNewTab
          openInNewTabAriaLabel="Opens in a new tab."
          openInExternalDomainAriaLabel="Opens a different website."
          id="main-level-icons-4"
          label="Tapahtumat.hel.fi"
          openInExternalDomainAriaLabel="Opens a different website"
          withDivider
        />
      </SideNavigation>
    </div>
  </div>)
}}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-sidenavigation--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/sideNavigation" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCrossCircle mdxType="IconCrossCircle" />{` No `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-sidenavigation--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`defaultOpenMainLevels`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default value for open main levels.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[`}{`]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`toggleButtonLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the mobile menu toggle button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ariaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The aria-label for helping screen reader users to distinguish navigation row from other navigational components`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:SideNavigation properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      